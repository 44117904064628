<template>
  <div id="auth">
    <div class="container-scroller">
      <div class="container-fluid page-body-wrapper full-page-wrapper">
        <div class="content-wrapper d-flex align-items-center auth px-0">
          <div class="row w-100 mx-0">
            <div class="col-lg-4 mx-auto">
              <div class="auth-form-light text-left py-5 px-4 px-sm-5">
                <div class="brand-logo text-center">
                  <img src="assets/images/logo.png" alt="logo" />
                </div>
                <h4 class="font-weight-bold text-center">Inicia sesión</h4>
                <div v-if="msg_auth" class="alert alert-danger mt-3 mb-3">
                  <i class="ti-alert"></i> {{ msg_auth }}
                </div>
                <form v-on:submit.prevent="set_auth" class="pt-3">
                  <div class="form-group">
                    <input
                      v-model="email"
                      type="text"
                      class="form-control form-control-lg"
                      id="exampleInputEmail1"
                      placeholder="Usuario"
                    />
                    <span v-if="validate.email" class="text-danger">
                      <i class="ti-alert"></i> Digita tu nombre de usuario
                    </span>
                  </div>
                  <div class="form-group">
                    <input
                      v-model="password"
                      type="password"
                      class="form-control form-control-lg"
                      id="exampleInputPassword1"
                      placeholder="Contraseña"
                    />
                    <span v-if="validate.password" class="text-danger">
                      <i class="ti-alert"></i> Digita contraseña
                    </span>
                  </div>
                  <div class="mt-3">
                    <button
                      type="submit"
                      class="btn btn-block btn-primary btn-lg font-weight-bold auth-form-btn"
                    >
                      Iniciar
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        <!-- content-wrapper ends -->
      </div>
      <!-- page-body-wrapper ends -->
    </div>
  </div>
</template>
<script>
import axios from "axios";
export default {
  name: "Auth",
  data() {
    return {
      loading: true,
      email: null,
      password: null,
      validate: {
        email: false,
        password: false,
      },
      msg_auth: false,
    };
  },
  created: function () {
    this.get_validate_auth();
  },
  methods: {
    get_validate_auth: function () {
      var session = localStorage.getItem("sess_taqui_web");
      if (session == null) {
        this.loading = false;
        return false;
      }
      this.$router.push("/home");
    },
    set_auth: function () {
      this.validate.email = false;
      this.validate.password = false;
      this.msg_auth = false;
      if (this.email == null || this.email == "") {
        this.validate.email = true;
      }
      if (this.password == null || this.password == "") {
        this.validate.password = true;
      }
      if (this.validate.email || this.validate.password) {
        return false;
      }
      const headers = {
        "Content-Type": "application/json",
        "x-api-key": this.$api_key,
      };
      let params = {
        email: this.email,
        password: this.password,
      };
      const vm = this;
      axios
        .post(this.$base_url + "auth/login", params, {
          headers: headers,
        })
        .then((response) => {
          if (!response.data.status) {
            vm.msg_auth = response.data.message;
            return false;
          }
          vm.msg_auth = false;
          var session = {
            token: response.data.token,
            usuario: response.data.data,
          };
          localStorage.clear();
          localStorage.setItem("sess_taqui_web", JSON.stringify(session));
          location.reload();
        })
        .catch((error) => {
          if (error == "Error: Network Error") {
            vm.msg_auth = "No se ha detectado conexión con el servidor.";
          } else {
            vm.msg_auth = error;
          }
        })
        .finally();
    },
  },
};
</script>